import { LoaderStyles } from './styles';

/**
 * @method Loader
 */
export default function Loader({ loading }) {
	return (
		<LoaderStyles loading={loading ? 'true' : 'false'}>
			<div className="body">
				<span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</span>
				<div className="base">
					<span></span>
					<div className="face"></div>
				</div>
			</div>
			<div className="longfazers">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
			<p>Loading</p>
		</LoaderStyles>
	);
}
