import styled from 'styled-components';

const ProductDetailPageStyles = styled.div`
	height: 100vh;
	.container {
		height: 100%;
        width: 100%;
		display: flex;
		flex-direction: row;
        .col {
            flex: 1;
        }
        .image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
        .detail {
            padding: 20px;
            color: white;
            h1 {
                letter-spacing: 2px;
                background: linear-gradient(to right, #ff6b6b, #6b47ff);
                -webkit-background-clip: text;
                color: transparent;
                text-transform: uppercase;
            }
        }
        @media screen and (max-width: 900px) {
            flex-direction: column;
            .col {
                flex: auto;
            }
            .image {
                height: 300px;
            }
        }
    }
    @media screen and (max-width: 900px) {
       height: auto;
    }
`;

export { ProductDetailPageStyles };
