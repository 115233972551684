import { ProductRouteStyles } from './styles';
import Products from '../../components/Products';
import GlobalStyles from '../../globalStyles';

/**
 * @method ProductsRoute
 */
export default function ProductsRoute() {
	return (
		<ProductRouteStyles>
			<GlobalStyles />
			<Products/>
		</ProductRouteStyles>
	);
}
