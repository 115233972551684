import styled from 'styled-components';

const GalleryStyles = styled.div`
	position: relative;
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	jusitfy-content: center;
	flex-direction: column;
	h1 {
		color: white;
		text-align: center;
	}
	.slick-slider {
		width: 90%;
	}
	.image-container {
		display: flex;
		align-items: center;
		jusitfy-content: center;
		img {
			height: 100vh;
		}
	}
`;

export { GalleryStyles };
