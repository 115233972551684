import { useEffect, useState } from 'react';
import { ProductStyles, ProductCategoryStyles } from './styles';
import env from '../../configs/env';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';

const Item = styled(Paper)(({ theme, textColor }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	color: textColor,
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center'
}));

/**
 * @method FullWidthGrid
 */
function FullWidthGrid({ items }) {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={0.5}>
				{items.map((item, index) => (
					<Grid key={index} item xs={12} md={4}>
						{item}
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

/**
 * @method Products
 */
export default function Products({ state }) {
	const { setGotProducts = () => {} } = state;
	const [productCategories, setProductCategories] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${env.backendURL}/get-products`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const fetchedProductCategories = await response.json();
				setProductCategories(fetchedProductCategories);
				setGotProducts(true);
			} catch (error) {
				console.error('Error fetching products: ', error);
			}
		};
		fetchData();
		return () => {};
	}, []);

	const applyFontColor = (color) => {
		return color ? color : 'white';
	};

	return (
		<ProductStyles>
			<h1 id="products" className={`item center`}>
				PRODUCTS
			</h1>
			<div className="product-category-container">
				{productCategories && (
					<FullWidthGrid
						items={productCategories.map((data) => (
							<Item key={data.categoryId}>
								<ProductCategoryStyles>
									<NavLink to={`/product-category/${data.categoryId}`}>
										<div className="container" style={{ backgroundImage: `url(${data.img})`, color: applyFontColor(data.color) }}>
											{data.title}
										</div>
									</NavLink>
								</ProductCategoryStyles>
							</Item>
						))}
					/>
				)}
			</div>
		</ProductStyles>
	);
}
