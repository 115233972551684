import { useState, useEffect } from 'react';
import { ProductCategoryCardStyles } from './styles';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import env from '../../configs/env';
import ProductDetailCard from '../ProductDetailCard';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Loader from '../Loader';
import getContentUI from '../../helpers/getContentUI';

const Item = styled(Paper)(({ theme, textColor }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	color: textColor,
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center'
}));

/**
 * @method FullWidthGrid
 */
function FullWidthGrid({ items }) {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={0.5}>
				{items.map((item, index) => (
					<Grid key={index} item xs={12} md={4}>
						{item}
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

/**
 * @method ProductCategoryCard
 */
export default function ProductCategoryCard({ id, category }) {
	const [products, setProducts] = useState(null);

	useEffect(() => {
		if (id) {
			fetchData();
		}

		return () => {};
	}, [id]);

	/**
	 * @method fetchData
	 */
	async function fetchData() {
		try {
			const response = await fetch(`${env.backendURL}/get-products-by-category?categoryId=${id}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const fetchedProduct = await response.json();
			setProducts(fetchedProduct);
		} catch (error) {
			console.error('Error fetching products: ', error);
		}
	}

	if (products === null || !category.img) return <Loader loading={true} />;

	return (
		<ProductCategoryCardStyles>
			<div className="container">
				<div
					className="col image"
					style={{
						backgroundImage: `url(${category.img})`
					}}
				></div>
				<div className="col detail">
					<h1>{category.title}</h1>
					<p>Category ID: {category.categoryId}</p>
					{products.length === 0 && (
						<div>
							<div>
								<p>
									Email: <a href="mailto:Info@capetownss.com">Info@capetownss.com</a>
								</p>
								<p>
									Phone: <a href="tel:+27218224762">+27 21 822 4762</a>
								</p>
							</div>
						</div>
					)}
					{products && (
						<div className="products">
							<FullWidthGrid
								items={products.map((data) => (
									<Item key={data.order}>
										<NavLink to={`/product-detail/${data.id}`}>
											<ProductDetailCard key={data.id} data={data} />
										</NavLink>
									</Item>
								))}
							/>
						</div>
					)}
					{category && category.additionalContent && (
						<div className="detail">
							<hr />
							{category.additionalContent.map((data, index) => {
								return <div key={`additional-content-item ${index}`}>{getContentUI(data)}</div>;
							})}
							<hr />
							<br />
						</div>
					)}
					<br />
					<br />
					<NavLink to={`/?scrollTo=products`}>
						<Button variant="outlined">Back</Button>
					</NavLink>
				</div>
			</div>
		</ProductCategoryCardStyles>
	);
}
