import React, { useRef, useEffect } from 'react';

const VideoPlayer = ({ videoUrl }) => {
	const videoRef = useRef(null);

	useEffect(() => {
		const video = videoRef.current;

		const handleEnd = () => {
			if (video.currentTime === video.duration) {
				video.playbackRate *= -1; // Reverse playback direction
				video.play(); // Start playing again
			}
		};

		video.addEventListener('ended', handleEnd);

		return () => {
			video.removeEventListener('ended', handleEnd);
		};
	}, []);

	return (
		<div className="video-self-hosted">
			<video ref={videoRef} autoPlay loop muted style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}>
				<source src={videoUrl} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	);
};

export default VideoPlayer;
