import styled from 'styled-components';

const ServiceDetailStyles = styled.div`
    margin: 0;
    padding: 0;
    background-color: #24252d;
    overflow-x: hidden;
`;

export { ServiceDetailStyles };
