import { createGlobalStyle } from 'styled-components';

const theme = {
	colors: {
		primary: '#034aa6',
		secondary: '#ef6f1c',
		red: '#941416',
		accent: '#ffa63c',
		button: '#ff6b6b'
	}
};

export { theme };

export default createGlobalStyle`
    import "slick-carousel/slick/slick.css";
    import "slick-carousel/slick/slick-theme.css";

    html {
      scroll-behavior: smooth;
    }


    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        scroll-behavior: smooth;
    }

    h1 {
        
        font-family: 'Anton', sans-serif;
        margin: 0;
        font-size: 5rem;
        letter-spacing: 4px;
        @media screen and (max-width: 1000px) {
			font-size: 2.5rem;
			line-height: 45px;
			letter-spacing: 2.5px;
		}
    }

    p {
        font-family: monospace;
        line-height: 25px;
        font-size: 18px;
        span {
            font-weigth: bold;
            font-size: 20px;
            color: #ff6b6b;
        }
    }

    body, #root {
        margin: 0;
        padding: 0;
        background-color: #24252d;
        overflow-x: hidden;
    }

    input,
    textarea,
    select,
    a,
    button {
        outline-style: none;
        box-shadow: none;
        border-color: transparent;
    }

    .MuiButtonBase-root {
        color: ${theme.colors.button} !important;
        border: 1px ${theme.colors.button} solid !important;
    }

    .MuiButton-contained {
        background: ${theme.colors.button} !important;
        color: black !important;
    }

    a {
        color: yellow;
        font-weight: bold;
    }

    /* Customize the scrollbar track */
    ::-webkit-scrollbar {
        width: 12px; /* Width of the scrollbar */
        border-left: 1px solid red;
    }

    /* Customize the scrollbar thumb (the draggable part) */
    ::-webkit-scrollbar-thumb {
        background: #888; /* Color of the thumb */
    }

    /* Customize the scrollbar track on hover */
    ::-webkit-scrollbar-track {
        background: #24252d; /* Color of the track on hover */
    }
`;
