import styled from 'styled-components';

const ProductDetailCardStyles = styled.div`
    img {
       width: 100%;
    }
`;


export { ProductDetailCardStyles };
