import { useState, useEffect } from 'react';
import { WhatWeDoStyles } from './styles';
import LottieAnimation from '../LottieAnimation';
// import { NavLink } from 'react-router-dom';
// import Button from '@mui/material/Button';
import env from '../../configs/env';

/**
 * @method WhatWeDo
 */
export default function WhatWeDo({ state }) {
	const { setGotServices = () => {} } = state;
	const [services, setServices] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${env.backendURL}/get-services`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const fetchedServices = await response.json();
				setServices(fetchedServices);
				setGotServices(true);
			} catch (error) {
				console.error('Error fetching services: ', error);
			}
		};
		fetchData();
		return () => {};
	}, []);

	return (
		<WhatWeDoStyles>
			<h1 id={'whatWeDo'} className={`item  center`}>
				WHAT WE DO
			</h1>
			<div className="services">
				{services &&
					services.map(({ heading, text, animation, id }, index) => {
						const position = index % 2 === 0 ? 'right' : 'left';
						const animationUI = (
							<div className="animation">
								<LottieAnimation animationData={JSON.parse(animation)} />
							</div>
						);
						return (
							<div id={heading} key={heading} className={`item ${position}`}>
								{position === 'right' && animationUI}
								<div className="text">
									<h2>{heading}</h2>
									<p dangerouslySetInnerHTML={{ __html: text }}></p>
									{/* <NavLink to={`/service-detail/${id}`}>
										<Button variant="outlined">Learn More</Button>
									</NavLink> */}
								</div>
								{position === 'left' && animationUI}
							</div>
						);
					})}
			</div>
			<div className="commitment">
				<h1
					style={{
						textTransform: 'uppercase'
					}}
				>
					Commitment
				</h1>
				<p>
					With addition to our Supply & Fabrication Services we offer all Clients the satisfaction of our Commitment to adding value to their
					Projects. We take great pride for being selected by CITY OF CAPE TOWN in a Supplier Development Program we’ve been selected for. Cape Town
					Stainless Steel would like to thank CITY OF CAPE TOWN for selecting and investing in us while growing us into a sustainable and formidable
					business growing CAPE TOWN as the leading Destination in Africa (See our Certificate OF Achievement here (
					<a
						href="https://firebasestorage.googleapis.com/v0/b/cape-town-stainless-steel.appspot.com/o/Cape%20Town%20Stainless%20Steel(PTY)Ltd%20-%20COCT%20Supplier%20Development%20Certificate%20-%202024.pdf?alt=media&token=8c344fd6-af60-4505-96d7-aef8b04c8fc6"
						target="_blank"
					>
						COCT SUPPLIER ATTACHMNET
					</a>
					))
				</p>
			</div>
		</WhatWeDoStyles>
	);
}
