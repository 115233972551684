import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme } from './globalStyles';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		main: '#034aa6'
	},
	fonts: {
		body: '"Roboto", sans-serif',
		heading: '"Anton", sans-serif',
		monospace: 'monospace, sans-serif'
	},
	...theme
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<ThemeProvider theme={darkTheme}>
			<RouterProvider router={router} />
		</ThemeProvider>
	</React.StrictMode>
);
