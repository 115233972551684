import styled from 'styled-components';
import { theme } from '../../globalStyles';

const ContactStyles = styled.div`
	position: relative;
	bacground-color: #393b4a;

	.border {
		width: 100%;
		position: absolute;
		height: 50px;
		z-index: 5;
		transform: rotate(180deg);
	}
	.border.top {
		top: 0;
	}

	.container {
		background: #393b4a;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-bottom: 100px;
		h1 {
			position: relative;
			width: 100%;
			text-align: center;
			padding-top: 100px;
			@media screen and (max-width: 1200px) {
				padding-top: 50px;
			}
			@media screen and (max-width: 600px) {
				padding-top: 50px;
			}
		}

		p {
			color: white;
		}

		button {
			background-color: ${theme.colors.accent};
			border-radius: 100%;
			height: 100px;
			width: 100px;
			margin-top: 50px;
			font-family: monospace;
			line-height: 25px;
			font-size: 25px;
			font-weight: bold;
			letter-spacing: 5px;
			cursor: pointer;
			transition: all 1s ease-in;
		}
		button:hover {
			transform: rotate(180deg);
			box-shadow: 0 0 10px #ffa63c;
		}
	}
`;

export { ContactStyles };
