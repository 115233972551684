import styled from 'styled-components';

const ProductStyles = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: #24252d;
	overflow-x: hidden;
	margin-top: 100px;
	margin-bottom: 100px;
	@media screen and (max-width: 1200px) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
	@media screen and (max-width: 900px) {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	h1 {
		position: relative;
		margin-top: 0;
		color: white;
		width: 100%;
		margin-top: 100px;
		text-align: center;
		@media screen and (max-width: 600px) {
			margin-top: 25px;
		}
	}
`;

const ProductCategoryStyles = styled.div`
	.container {
		padding-top: 45vh;
		// border: 1px solid white;
		border-radius: 2px;
		max-width: 800px;
		text-align: center;
		background-size: cover;
		background-position: center;
		cursor: pointer;
		transition: all 0.5s ease-in;
		width: 100%;
		font-size: 4rem;
		color: white;
		font-family: 'Roboto', sans-serif; /* Apply your cool font */
		@media screen and (max-width: 1200px) {
			font-size: 3rem;
		}
		@media screen and (max-width: 900px) {
			font-size: 2rem;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
			font-size: 2rem;
		}
		a {
			color: #294b77 !important;
		}
	}
	.container:hover {
		// transform: scale(1.1);
		opacity: 0.4;
	}
`;

export { ProductStyles, ProductCategoryStyles };
