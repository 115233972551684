import { useEffect, useState } from 'react';
import { ProductDetailStyles } from './styles';
import GlobalStyles from '../../globalStyles';
import ProductDetailPage from '../../components/ProductDetailPage';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import env from '../../configs/env';

/**
 * @method ProductDetail
 */
export default function ProductDetail() {
	const { id } = useParams();

	const [productDetail, setProductDetail] = useState([]);
	const [loading, setLoading] = useState(true); // Added loading state

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${env.backendURL}/get-product?id=${id}`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const fetchedProduct = await response.json();
				setProductDetail(fetchedProduct);
				setLoading(false); // Set loading to false after data fetching is done
			} catch (error) {
				console.error('Error fetching products: ', error);
			}
		};
		fetchData();
		return () => {};
	}, [id]); // Added id as a dependency to re-run effect when id changes

	return (
		<ProductDetailStyles>
			<GlobalStyles />
			{loading && <Loader loading={loading} />}
			<ProductDetailPage product={productDetail} />
		</ProductDetailStyles>
	);
}
