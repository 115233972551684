import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import VideoPlayerSelfHosted from '../components/VideoPlayerSelfHosted';

/**
 * @method getContentUI
 */
export default function getContentUI(data) {
	switch (data.type) {
		case 'document':
			return (
				<embed src={data.src} type="application/pdf" width="100%" height="600px" />
			);
		case 'button':
			return (
				<NavLink to={`/${data.route}`}>
					<Button variant={data.style}>{data.label}</Button>
				</NavLink>
			);
		case 'video':
			return (
				<VideoPlayerSelfHosted videoUrl={data.src} />
			);
		default:
			break;
	}
	return <div></div>;
}
