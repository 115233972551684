import React, { useRef, useEffect } from 'react';
import { LottieAnimationStyles } from './styles';
import lottie from 'lottie-web';

/**
 * @method LottieAnimation
 */
export default function LottieAnimation({ animationData, style = {} }) {
	const lottieContainerRef = useRef(null);

	useEffect(() => {
		const instance = lottie.loadAnimation({
			container: lottieContainerRef.current,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: animationData
		});
		// Return clean up function here
		return () => {
			instance.destroy();
		};
	}, []);

	return (
		<LottieAnimationStyles style={style}>
			<div ref={lottieContainerRef} className="lottie-container"></div>
		</LottieAnimationStyles>
	);
}
