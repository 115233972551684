import styled from 'styled-components';

const ServiceDetailCardStyles = styled.div`
	height: 100vh;
    .vimeo-player {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
	.container {
		height: 100%;
        width: 100%;
		display: flex;
		flex-direction: row;
        .video-self-hosted {
            position: fixed;
            width: 48%;
            height: 100%;
            overflow: hidden;
        }
        .col {
            flex: 1;
        }
        .detail {
            padding: 20px;
            color: white;
            h1 {
                letter-spacing: 2px;
                background: linear-gradient(to right, #ff6b6b, #6b47ff);
                -webkit-background-clip: text;
                color: transparent;
                text-transform: uppercase;
            }
        }
        @media screen and (max-width: 900px) {
            flex-direction: column;
            .col {
                flex: auto;
            }
            .image {
                height: 300px;
            }
            .video-self-hosted {
                position: relative;
                width:  100%;
                height: 100%;
                overflow: hidden;
            }
        }
    }
    @media screen and (max-width: 900px) {
       height: auto;
    }
`;

export { ServiceDetailCardStyles };
