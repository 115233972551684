import { ContactStyles } from './styles';
import borderTop from './border-top.svg';
import { NavLink } from 'react-router-dom';

/**
 * @method Contact
 */
export default function Contact() {
	return (
		<ContactStyles>
			<div
				className="border top"
				style={{
					backgroundImage: `url(${borderTop})`
				}}
			></div>
			<div className="container">
				<h1 id={'whatWeDo'} className={`item  center`}>
					CONTACT
				</h1>
				<p>Ready for a quote or have an enquiry?</p>
				<form action="">
					<NavLink to={`/contact-us`}>
						<button type="submit">YES</button>
					</NavLink>
				</form>
			</div>
		</ContactStyles>
	);
}
