import React from 'react';
import { ContactStyles } from './styles';
import ContactUsForm from '../../components/ContactUsForm';
import GlobalStyles from '../../globalStyles';

/**
 * @method Contact
 */
export default function Contact() {
	return (
		<ContactStyles>
			<GlobalStyles />
			<ContactUsForm />
		</ContactStyles>
	);
}
