import { ProductDetailPageStyles } from './styles';
import { NavLink, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import getContentUI from '../../helpers/getContentUI';

/**
 * @method ProductDetailPage
 */
export default function ProductDetailPage({ product }) {
	const navigate = useNavigate();
	return (
		<ProductDetailPageStyles>
			<div className="container">
				<div
					className="col image"
					style={{
						backgroundImage: `url(${product.img})`
					}}
				></div>
				<div className="col detail">
					<h1>{product.title}</h1>
					{/* <p dangerouslySetInnerHTML={{ __html: product.desc }}></p> */}
					<div>
						<div>
							<p>
								Email: <a href="mailto:Info@capetownss.com">Info@capetownss.com</a>
							</p>
							<p>
								Phone: <a href="tel:+27218224762">+27 21 822 4762</a>
							</p>
						</div>
					</div>
					{product && product.additionalContent && (
						<div className="detail">
							<hr />
							{product.additionalContent.map((data, index) => {
								return <div key={`additional-content-item ${index}`}>{getContentUI(data)}</div>;
							})}
							<hr />
							<br />
						</div>
					)}
					<br />
					<NavLink
						to="#"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1); // Navigate back to the previous page
						}}
					>
						<Button variant="outlined">Back</Button>
					</NavLink>
				</div>
			</div>
		</ProductDetailPageStyles>
	);
}
