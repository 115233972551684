import styled from 'styled-components';

const WhatWeDoStyles = styled.div`
	position: relative;
	background: #24252d;
	padding-top: 100px;
	padding-bottom: 100px;
	@media screen and (max-width: 1200px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@media screen and (max-width: 900px) {
		padding-top: 25px;
		padding-bottom: 25px;
	}
	@media screen and (max-width: 600px) {
	}
	h1 {
		position: relative;
		margin-top: 0;
		background: linear-gradient(to right, #ff6b6b, #6b47ff);
		-webkit-background-clip: text;
		color: transparent;
		width: 100%;
		text-align: center;
	}
	.services {
		position: relative;
		margin-top: 100px;
		display: flex;
		flex-direction: column;
		gap: 100px;
		padding: 0 200px;
		@media screen and (max-width: 1000px) {
			padding: 0 5%;
		}
		@media screen and (max-width: 900px) {
			margin-top: 50px;
			gap: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 600px) {
			padding: 0 2%;
		}
		.item {
			position: relative;
			display: flex;
			flex-direction: row;
			gap: 50px;
			@media screen and (max-width: 900px) {
				gap: 25px;
			}
			@media screen and (max-width: 600px) {
				flex-direction: column;
			}
			.text {
				color: white;
				width: 100%;
				h2 {
					letter-spacing: 2px;
					background: linear-gradient(to right, #ff6b6b, #6b47ff);
					-webkit-background-clip: text;
					color: transparent;
					text-transform: uppercase;
				}
				p {
					font-family: monospace;
					line-height: 25px;
					font-size: 18px;
					span {
						font-weigth: bold;
						font-size: 20px;
						color: #ff6b6b;
					}
				}
			}
			.animation {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.item.left {
		}
		.item.right {
			justify-content: right;
		}
	}
	.commitment {
		color: white;
		text-align: center;
		position: relative;
		width: 95%;
		margin: auto;
		margin-top: 100px;
		h2 {
			text-transform: uppercase;
			letter-spacing: 4px;
		}
		@media screen and (max-width: 900px) {
			margin-top: 50px;
		}
	}
	.bottom-border {
		width: 100%;
		position: absolute;
		bottom: 0;
		height: 50px;
		z-index: 5;
	}
`;

export { WhatWeDoStyles };
