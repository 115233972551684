import { useEffect, useState } from 'react';
import { ServiceDetailStyles } from './styles';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import ServiceDetailCard from '../../components/ServiceDetailCard';
import GlobalStyles from '../../globalStyles';
import env from '../../configs/env';

/**
 * @method ServiceDetail
 */
export default function ServiceDetail() {
	const { id } = useParams();

	const [serviceDetail, setServiceDetail] = useState([]);
	const [loading, setLoading] = useState(true); // Added loading state

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${env.backendURL}/get-service?id=${id}`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const fetchedService = await response.json();
				setServiceDetail(fetchedService);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching products: ', error);
			}
		};
		fetchData();
		return () => {};
	}, [id]); // Added id as a dependency to re-run effect when id changes

	return (
		<ServiceDetailStyles>
			<GlobalStyles />
			{loading && <Loader loading={loading} />}
			<ServiceDetailCard service={serviceDetail} />
		</ServiceDetailStyles>
	);
}
