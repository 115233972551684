import { useEffect, useState } from 'react';
import { ProductCategoryStyles } from './styles';
import { useParams } from 'react-router-dom';
import env from '../../configs/env';
import Loader from '../../components/Loader';
import ProductCategoryCard from '../../components/ProductCategoryCard';
import GlobalStyles from '../../globalStyles';

/**
 * @method ProductCategory
 */
export default function ProductCategory() {
	const { id } = useParams();

	const [productCategory, setProductCategory] = useState(null);
	const [loading, setLoading] = useState(true); // Added loading state

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${env.backendURL}/get-product-category?categoryId=${id}`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const fetchedProductCategory = await response.json();
				setProductCategory(fetchedProductCategory[0]);
				setLoading(false); // Set loading to false after data fetching is done
			} catch (error) {
				console.error('Error fetching products: ', error);
			}
		};
		if (id) {
			fetchData();
		}
		return () => {};
	}, [id]); // Added id as a dependency to re-run effect when id changes

	if (!id || !productCategory || loading) {
		return (
			<div>
				<GlobalStyles />
				<Loader loading={loading} />
			</div>
		);
	}

	return (
		<ProductCategoryStyles>
			<GlobalStyles />
			<ProductCategoryCard id={id} category={productCategory} />
		</ProductCategoryStyles>
	);
}
