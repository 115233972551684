import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import ProductsRoute from './routes/ProductsRoute';
import ProductCategory from './routes/ProductCategory';
import ProductDetail from './routes/ProductDetail';
import ServiceDetail from './routes/ServiceDetail';
import Contact from './routes/Contact';
import SendMessage from './routes/SendMessage';
import ErrorPage from './components/ErrorPage';

/**
 * @method router
 */
export default createBrowserRouter([
	{
		path: '/',
		element: <App />,
		errorElement: <ErrorPage />
	},
	{
		path: '/contact-us',
		element: <Contact />,
		errorElement: <ErrorPage />
	},
	{
		path: '/send-messsage',
		element: <SendMessage />,
		errorElement: <ErrorPage />
	},
	{
		path: '/products',
		element: <ProductsRoute />,
		errorElement: <ErrorPage />
	},
	{
		path: '/product-category/:id',
		element: <ProductCategory />,
		errorElement: <ErrorPage />
	},
	{
		path: '/product-detail/:id',
		element: <ProductDetail />,
		errorElement: <ErrorPage />
	},
	{
		path: '/service-detail/:id',
		element: <ServiceDetail />,
		errorElement: <ErrorPage />
	}
]);
