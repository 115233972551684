import React from 'react';
import { GalleryStyles } from './styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

/**
 * @method Gallery
 */
export default function Gallery() {
	/**
	 * @method generateNumberArray
	 */
	function generateNumberArray(n) {
		const array = [];
		for (let i = 1; i <= n; i++) {
			array.push(i);
		}
		return array;
	}

	return (
		<GalleryStyles>
			<h1>Gallery</h1>
			<ImageList variant="masonry" cols={3} gap={8}>
				{generateNumberArray(23).map((item) => (
					<ImageListItem key={item}>
						<img src={`/img/${item}.jpg?w=248&fit=crop&auto=format`} loading="lazy" />
					</ImageListItem>
				))}
			</ImageList>
		</GalleryStyles>
	);
}
