import styled from 'styled-components';

const ProductCategoryCardStyles = styled.div`
	height: 100vh;
	.container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		.col {
			flex: 1;
		}
		.image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.detail {
			padding: 20px;
			color: white;
			h1 {
				color: white;
				font-family: 'Roboto', sans-serif; /* Apply your cool font */
				text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for readability */
			}
		}
		@media screen and (max-width: 900px) {
			flex-direction: column;
			.col {
				flex: auto;
			}
			.image {
				height: 300px;
			}
		}
	}
	@media screen and (max-width: 900px) {
		height: auto;
	}

	a {
		color: #8e97a1
	}
`;

export { ProductCategoryCardStyles };
