import styled from 'styled-components';

const FooterStyles = styled.div`
	padding-top: 0;
	color: white;
	font-family: arial;
	text-align: center;
	address {
		iframe {
			margin-bottom: 50px;
		}
	}
	.myally {
		margin-top: 50px;
	}
	.legal {
		margin-top: 50px;
		color: white;
		font-family: arial;
		padding: 0px 10px;
		text-align: center;
		p {
			font-size: 12px;
			line-height: 16px;
		}
	}
`;

export { FooterStyles };
