import React from 'react';
import { FooterStyles } from './styles';

/**
 * @method CopyrightDisclaimer
 */
const CopyrightDisclaimer = () => {
	const currentYear = new Date().getFullYear();
	return (
		<div className="legal">
			<p>&copy; {currentYear} Cape Town Stainless Steel (Pty) Ltd. All Rights Reserved.</p>
			<p>
				The content and images on this website are the property of Cape Town Stainless Steel (Pty) Ltd and are protected by copyright law. Any
				unauthorized use, reproduction, or distribution of the content without prior written permission from Cape Town Stainless Steel (Pty) Ltd is
				strictly prohibited.
			</p>
		</div>
	);
};

/**
 * @method Footer
 */
export default function Footer() {
	return (
		<FooterStyles>
			<address>
				<iframe
					title="Google Maps"
					width="100%"
					height="300"
					frameBorder="0"
					style={{ border: 0 }}
					src={`https://www.google.com/maps/embed/v1/place?q=Cape%20Town%20Stainless%20Steel,%20Unit%201%20%26%202%2030%20Zinfandel%20Street%20Saxenburg%202,%20Blackheath,%207580&key=AIzaSyATUpJGtiRf5qn2W2K9H7NfgID9s_bu4eA`}
					allowFullScreen
				></iframe>
				<div>Cape Town Stainless Steel, Unit 1 & 2 30 Zinfandel Street Saxenburg 2</div>
				<div>Blackheath, 7580</div>
				<div>Cape Town, ZA</div>
				<div>
					Email: <a href="mailto:Info@capetownss.com">Info@capetownss.com</a>
				</div>
			</address>
			<div className="legal">
				{/* <a
					href="https://firebasestorage.googleapis.com/v0/b/cape-town-stainless-steel.appspot.com/o/PAIA%20MANUAL.pdf?alt=media&token=4db19967-5247-4e3a-8dcc-345436520eb4"
					target="_blank"
				>
					Privacy Policy
				</a>
				<br />
				<br /> */}
				<a
					href="https://firebasestorage.googleapis.com/v0/b/cape-town-stainless-steel.appspot.com/o/PAIA%20MANUAL.pdf?alt=media&token=4db19967-5247-4e3a-8dcc-345436520eb4"
					target="_blank"
				>
					Privacy Policy
				</a>
			</div>
			{/* <div className="myally">
				Designed and Developed by:
				<br />
				<br />
				<a target="_blank" href="http://myally.co.za/home">
					My Ally Management Systems (Pty) Ltd
				</a>
			</div> */}
			<CopyrightDisclaimer />
		</FooterStyles>
	);
}
