import styled, { keyframes } from 'styled-components';

const fadeOutAnimation = keyframes`
  from {
	position: absolute;
    opacity: 1;
	left: -100px;
  }
  to {
	position: absolute;
    opacity: 0;
	left:  2000px;
  }
`;

const Hero1Styles = styled.div`
	min-height: 100vh;
	display: flex;
	align-items: center;
	overflow: hidden;
	background: url('https://firebasestorage.googleapis.com/v0/b/cape-town-stainless-steel.appspot.com/o/20196_Welding%20sparks_By_Ami_Bornstein_Artlist_HD.mp4?alt=media&token=5cba49ad-7a85-439c-8c0e-76d567c87fa8')
		no-repeat center center fixed;
	background-size: cover;
	.content {
		position: absolute;
		display: flex;
		flex-direction: row;
		align-items: center;
		top: 0;
		left: 0;
		height: 100vh;
		left: 20px;
		.header.fix {
			position: fixed;
			background: black;
			top: 0;
			left: 0;
			width: 100%;
			height: 65px;
			z-index: 999999;
			color: white;
		}
	}
	.image-background {
		width: 100%;
		position: absolute;
		height: 100%;
		left: 0;
		z-index: 1;
		background-position: center;
		background-size: cover;
	}
	.video-background {
		width: 100%;
		position: absolute;
		height: 100%;
		left: 0;
		z-index: 1;
	}
	.bottom-border {
		width: 100%;
		position: absolute;
		bottom: 0;
		height: 50px;
		z-index: 5;
	}
`;

const StyledHeading = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
	width: 100%;
	z-index: 999;
	h1 {
		margin: 0;
		transition: opacity 0.5s ease-in-out;
		position: absolute;
		color: #a59696;
		span {
			position: relative;
			margin-left: 40px;
		}
		width: 100vw;
		@media screen and (max-width: 1000px) {
			span {
				margin-left: 20px;
			}
		}
	}
	h1.fade-out {
		animation: ${fadeOutAnimation} 0.5s ease-in-out forwards;
	}
	h1.fix {
		position: fixed;
		font-size: 20px;
		letter-spacing: 1px;
		line-height: 23px;
		color: white;
		span {
			margin-left: 10px;
		}
	}
`;

export { Hero1Styles, StyledHeading };
