import { useEffect, useState } from 'react';
import { Hero1Styles, StyledHeading } from './styles';
import border from './border.svg';
// import VideoPlayerSelfHosted from '../VideoPlayerSelfHosted';

// const videoUrl =
// 	'https://firebasestorage.googleapis.com/v0/b/cape-town-stainless-steel.appspot.com/o/412155_Tap%20Contained%20Water%20Outside%20Dripping_By_Ami_Bornstein_Artlist_HD.mp4?alt=media&token=748c38d0-1bc7-4365-866b-811e130d919f';
const backgroundImage =
	'https://firebasestorage.googleapis.com/v0/b/cape-town-stainless-steel.appspot.com/o/vecteezy_industrial-water-treatment-plant-with-water_29562738.jpg?alt=media&token=a8517a95-ec4d-430c-88da-e4c656a9b4c5';

/**
 * @method Hero1
 */
export default function Hero1() {
	const [fixLogo, setFixLogo] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', scroll);
		return () => {
			window.removeEventListener('scroll', scroll);
		};
	}, []);

	/**
	 * @method scroll
	 */
	function scroll() {
		const scrollY = window.scrollY;
		setFixLogo(scrollY !== 0);
	}

	return (
		<Hero1Styles>
			<div className="content">
				<div className={`header ${fixLogo ? 'fix' : ''}`}>
					<StyledHeading>
						<h1 className={`${fixLogo ? 'fix' : ''}`}>
							CAPE TOWN
							<br />
							<span>STAINLESS STEEL</span>
						</h1>
					</StyledHeading>
				</div>
			</div>
			<div
				className="image-background"
				style={{
					backgroundImage: `url(${backgroundImage})`
				}}
			></div>
			{/* <div className="video-background">
				<VideoPlayerSelfHosted videoUrl={videoUrl} />
			</div> */}
			<div
				className="bottom-border"
				style={{
					backgroundImage: `url(${border})`
				}}
			></div>
		</Hero1Styles>
	);
}
