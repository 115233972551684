import styled from 'styled-components';
// import { theme } from '../../globalStyles';

const StyledSendMessage = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	.container {
		max-width: 600px;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		margin-top: 50px;
		flex-direction: column;
		h1 {
			position: relative;
			margin-top: 0;
			background: linear-gradient(to right, #ff6b6b, #6b47ff);
			-webkit-background-clip: text;
			color: transparent;
			width: 100%;
			text-align: center;
		}
		.office-hours {
			color: white;
		}
		p, ul {
			color: white;
			font-family: monospace;
			line-height: 25px;
			font-size: 18px;
			span {
				font-weigth: bold;
				font-size: 20px;
				color: #ff6b6b;
			}
		}
		form {
			width: 100%;
		}
		.failure {
			margin-top: 50px;
			color: #8d4141;
			letter-spacing: 5px;
		}
		.row {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			margin-top: 50px;
		}
		@media screen and (max-width: 600px) {
			max-width: 90%;
		}
	}
`;

export { StyledSendMessage };
