import styled from 'styled-components';
import { theme } from '../../globalStyles';

const ContactStyles = styled.div`
	overflow: hidden;
	.container {
		position: relative;
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		.col {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			display: flex;
			justifiy-content: center;
			align-items: center;
			text-align: center;
			a {
				width: 100vw;
				height: 50vh;
				display: flex;
				justifiy-content: center;
				align-items: center;
				text-align: center;
				border: none; /* Remove default button border */
				button {
					width: 100%;
					height: 100%;
					background: #24252d;
					color: white;
					font-family: 'Anton', sans-serif;
					margin: 0;
					font-size: 5rem;
					letter-spacing: 4px;
					cursor: pointer;
				}
				@media screen and (max-width: 1000px) {
					font-size: 2.5rem;
					line-height: 45px;
					letter-spacing: 2.5px;
				}
				:hover {
					background-color: ${theme.colors.secondary};
				}
				&:first-child {
					border-bottom: 1px solid ${theme.colors.accent}; /* Apply border to first button */
				}
			}
		}
	}
`;

export { ContactStyles };
