import { ServiceDetailCardStyles } from './styles';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import VideoPlayerSelfHosted from '../VideoPlayerSelfHosted';
// import getContentUI from '../../helpers/getContentUI';

/**
 * @method ServiceDetailCard
 */
export default function ServiceDetailCard({ service }) {
	if (!service.animation) return <div></div>;
	// TODO: check supply - render max evo video
	return (
		<ServiceDetailCardStyles>
			<div className="container">
				<div className="col image">{service && service.mainVideo && !service.vimeo && <VideoPlayerSelfHosted videoUrl={service.mainVideo} />}
					{service && service.mainVideo && service.vimeo && <iframe className='vimeo-player' title="vimeo-player" src={service.mainVideo} width="640" height="360" frameborder="0" allowfullscreen></iframe>}</div>
				<div className="col detail">
					<h1>{service.heading}</h1>
					<p dangerouslySetInnerHTML={{ __html: service.text }}></p>
					{/* {service && service.additionalContent && (
						<div className="detail">
							<hr />
							{service.additionalContent.map((data, index) => {
								return <div key={`additional-content-item ${index}`}>{getContentUI(data)}</div>;
							})}
							<hr />
							<br />
						</div>
					)} */}
					<br />
					<NavLink to={`/`}>
						<Button variant="outlined">Back</Button>
					</NavLink>
				</div>
			</div>
		</ServiceDetailCardStyles>
	);
}
