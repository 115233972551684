import { ContactStyles } from './styles';
import { NavLink } from 'react-router-dom';

/**
 * @method Contact
 */
export default function Contact() {
	return (
		<ContactStyles>
			<div className="container">
				<div className="col">
					<NavLink to={`/send-messsage?id=quote`}>
						<button type="button">QUOTE</button>
					</NavLink>
					<NavLink to={`/send-messsage?id=query`}>
						<button type="button">QUERY</button>
					</NavLink>
				</div>
			</div>
		</ContactStyles>
	);
}
