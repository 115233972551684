import { useState, useEffect } from 'react';
import GlobalStyles from './globalStyles';
import Loader from './components/Loader';
import Hero1 from './components/Hero1';
import WhatWeDo from './components/WhatWeDo';
// import WhoWeAre from './components/WhoWeAre';
import Products from './components/Products';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Gallery from './components/Gallery';

/**
 * @method App
 */
export default function App() {
	const [gotServices, setGotServices] = useState(false);
	// const [gotPeople, setGotPeople] = useState(false);
	const [gotProducts, setGotProducts] = useState(false);
	const loaded = gotServices && gotProducts;

	useEffect(() => {
		// Function to parse query string and get scrollTo parameter
		const getScrollToFromQuery = () => {
			const searchParams = new URLSearchParams(location.search);
			return searchParams.get('scrollTo');
		};

		// Get the scrollTo parameter from query string
		const scrollToId = getScrollToFromQuery();

		if (scrollToId) {
			// Find the DOM element with the id scrollToId
			const element = document.getElementById(scrollToId);

			if (element) {
				// Scroll to the element
				element.scrollIntoView({ behavior: 'smooth' });
			}

			// Remove query string from URL after scrolling
			const newUrl = location.pathname; // or any other path you want to redirect to
			const state = { ...history.state }; // preserve the existing state
			window.history.replaceState(state, '', newUrl);
		}
	}, []);

	// TODO: apply dark theme for material ui

	return (
		<div>
			<GlobalStyles />
			{!loaded && <Loader loading={!loaded} />}
			<>
				<Hero1 />
				<WhatWeDo state={{ gotServices, setGotServices }} />
				<Products state={{ gotProducts, setGotProducts }} />
				<Gallery />
				<Contact />
				{/* <WhoWeAre state={{ gotPeople, setGotPeople }} /> */}
				<Footer />
			</>
		</div>
	);
}
