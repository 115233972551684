import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import GlobalStyles from '../../globalStyles';
import TextField from '@mui/material/TextField';
import { StyledSendMessage } from './styles';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import env from '../../configs/env';

/**
 * Component for sending a message.
 * @returns {JSX.Element} SendMessage component.
 */
export default function SendMessage() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const queryType = queryParams.get('id'); // Get query type from query string

	const [step, setStep] = useState(1); // Step of the form
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [cellphone, setCellphone] = useState('');
	const [text, setText] = useState('');
	const [success, setSuccess] = useState(false);
	const [failure, setFailure] = useState(false);
	const [finished, setFinished] = useState(false);

	// Function to handle the next step
	const handleNext = (e) => {
		e.preventDefault();
		if (step === 1) {
			if (!name.trim() || !surname.trim()) {
				alert('Please fill in all required fields.');
				return;
			}
		} else if (step === 2) {
			if (!validateEmail(email)) {
				alert('Please enter a valid email address.');
				return;
			}
		}
		setStep(step + 1);
	};

	// Function to send the message
	const sendMessage = async (e) => {
		e.preventDefault();
		if (!cellphone.trim()) {
			alert('Please fill in all required fields.');
			return;
		}
		try {
			const response = await fetch(`${env.backendURL}/send-message`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					name,
					surname,
					email,
					cellphone,
					queryType,
					text
				})
			});

			// Parse the response
			const data = await response.json();

			if (response.ok) {
				// Set success state
				setSuccess(true);
				setFailure(false);
				setFinished(true);
				console.log('Message sent successfully!');
			} else {
				// Set failure state
				setFailure(true);
				setSuccess(false);
				setFinished(true);
				console.error('Failed to send message:', data.error);
			}
		} catch (error) {
			// Set failure state
			setFailure(true);
			setSuccess(false);
			setFinished(true);
			console.error('Failed to send message:', error);
		}
	};

	// Function to validate email
	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	return (
		<StyledSendMessage>
			<GlobalStyles />
			<div className="container">
				<h1>Contact Us</h1>
				{!finished && <p>Please provide us with the following info:</p>}
				{/* Step 1: Name and Surname */}
				{step === 1 && (
					<form onSubmit={handleNext}>
						<div className="row">
							<TextField
								label="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								autoComplete="given-name" // Autocomplete for first name
							/>
							<TextField
								label="Surname"
								value={surname}
								onChange={(e) => setSurname(e.target.value)}
								autoComplete="family-name" // Autocomplete for last name
							/>
							<Button type="submit">Next</Button>
						</div>
					</form>
				)}

				{/* Step 2: Email */}
				{step === 2 && (
					<form onSubmit={handleNext}>
						<div className="row">
							<TextField
								label="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								autoComplete="email" // Autocomplete for email
							/>
							<TextField
								label="Cellphone"
								value={cellphone}
								onChange={(e) => setCellphone(e.target.value)}
								autoComplete="tel" // Autocomplete for phone number
							/>
							<Button type="submit">Next</Button>
						</div>
					</form>
				)}

				{/* Step 3: Contact Number */}
				{step === 3 && !success && (
					<form onSubmit={sendMessage}>
						<div className="row">
							<TextField label="Message" value={text} onChange={(e) => setText(e.target.value)} multiline={true} />
							<Button type="submit">Send Message</Button>
						</div>
					</form>
				)}

				{/* Success page */}
				{success && (
					<div className="row">
						<p>Message sent successfully!</p>
						<div>
							<p>
								Email: <a href="mailto:Info@capetownss.com">Info@capetownss.com</a>
							</p>
							<p>
								Phone: <a href="tel:+27218224762">+27 21 822 4762</a>
							</p>
						</div>
						<p>Phone us during office hours to get the info you need quicker. Speed on your end will drive faster results!</p>
						<div className="office-hours">
							<p>Office Hours</p>
							<ul>
								<li>
									<strong>Monday:</strong> 8:00 AM - 5:00 PM
								</li>
								<li>
									<strong>Tuesday:</strong> 8:00 AM - 5:00 PM
								</li>
								<li>
									<strong>Wednesday:</strong> 8:00 AM - 5:00 PM
								</li>
								<li>
									<strong>Thursday:</strong> 8:00 AM - 5:00 PM
								</li>
								<li>
									<strong>Friday:</strong> 8:00 AM - 5:00 PM
								</li>
								<li>
									<strong>Saturday:</strong> Closed
								</li>
								<li>
									<strong>Sunday:</strong> Closed
								</li>
							</ul>
						</div>
						<NavLink to={`/`}>
							<Button variant="outlined">Back</Button>
						</NavLink>
					</div>
				)}

				{/* Failure message */}
				{failure && <div className="message failure">Failed to send message. Please try again later.</div>}
			</div>
		</StyledSendMessage>
	);
}
