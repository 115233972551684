import { ProductDetailCardStyles } from './styles';

/**
 * @method ProductDetailCard
 */
export default function ProductDetailCard({ data }) {
	return (
		<ProductDetailCardStyles>
			<img src={data.img} alt={data.title} />
			<h2>{data.title}</h2>
		</ProductDetailCardStyles>
	);
}
